import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import IconMarin_World from '../Images/icon_marine world.png';


const DisplayOrderCounter = (props) => {
  const { openDataActions } = props;
  const [wait_count, setWaitCount] = useState(null);

  const refreshOrderCount = async () => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/`)).data;
    setWaitCount(data.wait_count);
    openDataActions.set(data.open_data);
  }

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await refreshOrderCount();
      intervalId = setInterval(() => {
        refreshOrderCount();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
    return fetchData();
  }, []);

  return (
    <div className="container text-center my-3">
      <div className="row justify-content-center px-0 py-1 mt-1 mb-1">
        <div>
          <img src={IconMarin_World}/>
        </div>
        {wait_count !== null && (
          <div className='col-7 ml-2 mr-1'>
            <div className='row h-100'>
              <div className="col bg-white px-0 py-2 mt-4">
                <h2 className="d-inline mb-0">現在 </h2>
                <h1 className="mb-0 text-danger d-inline font-weight-bold">{wait_count.find(w => w.class === constClass.CLASS.TRAIN) ? wait_count.find(w => w.class === constClass.CLASS.TRAIN).count_order : 0}</h1>
                <h2 className="d-inline mb-0"> 組待ち</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DisplayOrderCounter;