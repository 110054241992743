import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import constClass from '../../Constants/Constants';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import Ticket from './Ticket';
import { useReactToPrint } from 'react-to-print';
import Modal from 'react-modal';
import ArrowOn from '../Images/arrow_blue.png';
import ArrowOff from '../Images/arrow_off.png';

const DisplayOrder = (props) => {
  const { openData } = props;
  const [disabled, setDisabled] = useState(false);
  // const [classId, setClassId] = useState(null);
  // const [message, setMessage] = useState(null);
  const [order, setOrder] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [Adult, setAdult] = useState(1);
  const [Child, setChild] = useState(0);
  const [Counter, setCounter] = useState(null);
  const { addToast } = useToasts();
  const componentRef = useRef();
  const location = useLocation();
  const modalCloseStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '25%',
      right: '25%',
      top: '50%',
      bottom: 'auto',
      marginTop: '-12rem',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto"
    }
  };
  const modalConfirmStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '25%',
      right: '25%',
      top: '50%',
      bottom: 'auto',
      marginTop: '-12rem',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto"
    }
  };

  const refreshOrder = async () => {
   setAdult(1);
   setChild(0);
   setCounter(null);
  }

  // const submitClass = (pClassId) => {
  //   setClassId(pClassId);
  //   setMessage(null);
  // }

  const printTicket = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
      size: 60mm 160mm;
      margin: 0mm;
    }`,
  });

  const submitClear = () => {
   setAdult(1);
   setChild(0);
   setCounter(null);
  }

  const submit = async () => {
    setDisabled(true);
    setConfirm(false);
    var data = {
      shop_id: constClass.SHOP_ID,
      line_id: constClass.PAPER_ORDER,
    };

    data[constClass.COLUMN.ADULT] = Adult;
    data[constClass.COLUMN.CHILD] = Child;
    data[constClass.COLUMN.COUNTER] = Counter;
    try {
      const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data)).data;
      //setMessage(`受付番号を発行しました`);
      addToast(`整理券を発行しました`, { appearance: 'success', autoDismiss: true })
      // 整理券発行処理
      setOrder(my_order.order);
      printTicket();
      setDisabled(false);
    } catch (err) {
      // setMessage(err);
      addToast(`エラーが発生しました。${err.response.status}:${err.response.data}`, { appearance: 'error', autoDismiss: true });
    } finally {
      setDisabled(false);
      await refreshOrder();
    }
  }

  return (
    <div className="container bg-white mx-3 px-0">      
      <div className="d-none">
        <Ticket order={order}　path={`${process.env.REACT_APP_FRONTEND_URL}/Display/SmartSignage`} ref={componentRef} />
      </div>
      <div className="row my-0 mx-0 px-0">
          <div className="col-12 px-0">
            <div>
{/*大人・子供*/}
              <div className="row px-0 mx-0">
                <div className="col section-dark px-0">
                  <h3 className="mb-0">{constClass.HEADING.ADULT_NAME}</h3>
                </div>
              </div>
              
              <div className="row mx-0 bg-white px-3 pt-3 pb-3">

                <div className = "col text-center bg-lightwhite p-1 ml-1 "> 
                  <div className="row col mx-0 bg-white px-3 pt-3 pb-3">
                    <div className="input-group justify-content-center"> 
                      
                      <div className="input-group-prepend col-3 text-center py-1  mr-1">
                        <button
                        className={'w-100 h-100 py-2 px-0 btn-blue-enable'}
                        onClick = {() => {if(Adult!== 0){setAdult(Adult-1)}}}
                        >
                          <h1 className="font-weight-bold mb-0">－</h1>
                        </button>
                      </div>

                      <div className = "form-group form-group-lg col-3">
                        <input className="form-control bg-white text-body text-font text-center w-100 h-100" type="text" value = {Adult} readOnly>                      
                        </input>
                      </div>
                      
                      <div className="input-group-append col-3 text-center py-1  ml-1">
                        <button                        
                        className={'w-100 h-100 py-2 px-0 btn-blue-enable'}
                        onClick = {() => setAdult(Adult+1)}
                        >
                        <h1 className="font-weight-bold mb-0">＋</h1>
                      </button>
                      
                      </div>
                    </div>
                   </div>
                </div>
              </div>

{/*未就学児*/}
              <div className="row mx-0">
                <div className="col section-dark">
                  <h3 className="mb-0">{constClass.HEADING.CHILD_NAME}</h3>
                </div>
              </div>
              <div className="row mx-0 bg-white px-3 pt-3 pb-2">
              <div className = "col text-center bg-lightwhite p-1 ml-1 "> 
                  <div className="row col mx-0 bg-white px-3 pt-3 pb-3">
                    <div className="input-group justify-content-center"> 
                      
                      <div className="input-group-prepend col-3 text-center py-1  mr-1">
                        <button
                        className={'w-100 h-100 py-2 px-0 btn-blue-enable'}
                        onClick = {() => {if(Child!== 0){setChild(Child-1)}}}
                        >
                          <h1 className="font-weight-bold mb-0">－</h1>
                        </button>
                      </div>

                      <div className = "form-group form-group-lg col-3">
                        <input className="form-control bg-white text-body text-font text-center w-100 h-100" type="text" value = {Child} readOnly>                      
                        </input>
                      </div>
                      
                      <div className="input-group-append col-3 text-center py-1  ml-1">
                        <button                        
                        className={'w-100 h-100 py-2 px-0 btn-blue-enable'}
                        onClick = {() => {setChild(Child+1)}}
                        >
                        <h1 className="font-weight-bold mb-0">＋</h1>
                      </button>
                      
                      </div>
                    </div>
                   </div>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col section-dark">
                  <h3 className="mb-0">{constClass.HEADING.COUNTER_NAME}</h3>
                </div>
              </div>

{/*カウンター*/}
              <div className="row mx-0 bg-white px-3 pt-3 pb-4">
                <div className="col text-center bg-lightwhite p-1 ml-1 rounded-left">
                  <button
                    disabled={disabled}
                    className={`btn ${Counter === constClass.COUNTER.COUNTER ? "btn-blue-active" : "btn-blue-enable"} w-100 h-100 py-2 px-0`}
                    onClick={() => { setCounter(constClass.COUNTER.COUNTER) }}>
                    <h2 className="font-weight-bold mb-0">{constClass.COUNTER_NAME.COUNTER}</h2>
                  </button>
                </div>
                <div className="col text-center bg-lightwhite py-1 pr-1 pl-0 mr-1 rounded-right">
                  <button
                    disabled={disabled}
                    className={`btn ${Counter === constClass.COUNTER.TABLE ? "btn-blue-active" : "btn-blue-enable"} w-100 h-100 py-2 px-0`}
                    onClick={() => { setCounter(constClass.COUNTER.TABLE) }}>
                    <h2 className="font-weight-bold mb-0">{constClass.COUNTER_NAME.TABLE}</h2>
                  </button>
                </div>
              </div>

{/*クリア・発見*/}
              {/*<div className="row mx-0 bg-white px-3 pt-3 pb-4">*/}
                <div className="row mb-2 mx-0">
                  <div className="col-auto text-left mx-1 pr-0">
                    <button
                      disabled={(disabled)}
                      className="btn-lg btn-submit-cancel w-100 rounded"
                      onClick={() => { submitClear() }}>
                      <h1 className="mb-0">クリア</h1>
                    </button>
                  </div>
                  <div className="col text-right mx-1 pl-0">
                    <button
                      disabled={((Adult === 0 && Child === 0 )|| Counter === null || disabled)}
                      className="btn-lg btn-blue-submit w-100 rounded"
                      onClick={() => { setConfirm(true) }}>
                      <h1 className="mb-0 align-middle d-inline-block mx-2">発券 </h1>
                      <img className="arrow-lg align-middle d-inline-block" src={(Adult === 0 && Child === 0 )|| Counter === null || disabled ? ArrowOff : ArrowOn} alt="" />
                    </button>
                  </div>
                </div>
              {/*</div>*/}
              {/* {message !== null && <p className="text-danger">{message}</p>} */}
            </div>
          </div>
      </div>
      
      <Modal isOpen={confirm} style={modalConfirmStyle}>
        <div className="row mt-3 mx-0">
          <div className="col text-center">
            <h2 className="mb-0">{`${constClass.HEADING.ADULT_NAME}【${Adult}】人`}</h2>
          </div>
        </div>
        <div className="row mt-1 mx-0">
          <div className="col text-center">
            <h2 className="mb-0">{`${constClass.HEADING.CHILD_NAME}【${Child}】人`}</h2>
          </div>
        </div>
        <div className="row mt-1 mx-0">
          <div className="col text-center">
            <h2 className="mb-0">{`${constClass.HEADING.COUNTER_NAME}【${constClass.COUNTER_NAME[Counter]}】`}</h2>
          </div>
        </div>
        <div className="row mt-3 mx-0">
          <div className="col text-center">
            <h2 className="mb-0">{`で整理券を発行します`}</h2>
          </div>
        </div>
        <div className="row my-3 mx-0">
          <div className="col">
            <button
              disabled={disabled}
              className={`btn btn-blue-enable w-100 py-2`}
              onClick={() => { setConfirm(false) }}>
              <h2 className="font-weight-bold mb-0">キャンセル</h2>
            </button>
          </div>
          <div className="col">
            <button
              disabled={disabled}
              className={`btn btn-blue-enable w-100 py-2`}
              onClick={() => { submit() }}>
              <h2 className="font-weight-bold mb-0">発行</h2>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DisplayOrder;